"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.stayLoggedIn = void 0;
var _throttle = _interopRequireDefault(require("lodash/throttle"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const TIMEOUT_MAX = 1800000;
const reload = () => {
  document.location.reload();
};
const renewSessionOrReloadPage = function (csrfToken, loggedIn) {
  let reloadFn = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : reload;
  const body = new FormData();
  body.append("csrfmiddlewaretoken", csrfToken);
  fetch("/stay-logged-in/", {
    method: "POST",
    body
  }).then(res => res.json()).then(function (data) {
    if (loggedIn && !data.logged_in) {
      reloadFn();
    }
  });
};
const stayLoggedIn = function (crsfToken) {
  let loggedIn = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  let timeout = arguments.length > 2 ? arguments[2] : undefined;
  let callback = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : renewSessionOrReloadPage;
  let authScheduleId = null;
  // check if the user is logged yet, and refresh the page after TIMEOUT_MAX.
  authScheduleId = setTimeout(callback, TIMEOUT_MAX, crsfToken, loggedIn);
  function cleanAndRescheduleAuthFunction() {
    if (authScheduleId) {
      clearTimeout(authScheduleId);
    }
    // check if the user is logged yet, and refresh the page after TIMEOUT_MAX.
    authScheduleId = setTimeout(callback, TIMEOUT_MAX, crsfToken, loggedIn);
  }
  const throttleStayLoggedIn = (0, _throttle.default)(cleanAndRescheduleAuthFunction, timeout, {
    leading: true,
    trailing: false
  });

  // Delay adding listeners so we don't make unecessary calls at the beginning of window
  setTimeout(function delayedListen() {
    ["mousemove", "click", "keydown", "scroll"].forEach(function (event) {
      document.addEventListener(event, throttleStayLoggedIn, {
        passive: true
      });
    });
  }, 30000);
};
exports.stayLoggedIn = stayLoggedIn;